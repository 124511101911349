import axiosInstance from 'util/axios'

export const processVeteranValor = async (code) => {
    try {
        const body = {
            code,
            ehrEndPointId: localStorage.getItem('EHREndPointId'),
        }
        const response = await axiosInstance.post(`/veteran/handle`, body)
        localStorage.removeItem('selectedProfileId')
        localStorage.removeItem('EHRconnected')
        localStorage.removeItem('EHREndPointId')
        return response
    } catch (error) {
        console.error('An error occurred:', error)
        localStorage.removeItem('selectedProfileId')
        localStorage.removeItem('EHRconnected')
        localStorage.removeItem('EHREndPointId')
        throw error
    }
}

export const getPatientVeteranDisability = async (profileId) => {
    try {
        const response = await axiosInstance.get(`/Profile/get-disability-data?profileId=${profileId}`)
        return response
    } catch (error) {
        console.error('An error occurred:', error)
        throw error
    }
}
