import React, { useState, useEffect } from 'react'
import oauth2 from 'simple-oauth2'
import 'react-phone-input-2/lib/style.css'
import Box from '@mui/material/Box'
import TextField from 'components/Form/TextField'
import Autocomplete from '@mui/lab/Autocomplete'
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined'
import OutboxOutlinedIcon from '@mui/icons-material/OutboxOutlined'
import isEmpty from 'lodash.isempty'
import Loader from 'components/Loader/Loader'
import { GetAllEndpointsAPI, GetVeteransEndpointsAPI } from 'services/Services'
import { NewConnectedEHRrecordsAPI } from 'services/Services'
import popupNotification from 'components/Notification/popupNotification'
import ImportButton from 'components/BlueImportButton'
import './ImportRecords.scss'
import { bluebuttonAuthorizationUri } from './Utils'
import config from 'use/Config'
import { MissingFaciltyBox, VeteransBox, FieldsBox, FacilityLabel, ColumnsHr } from './ImportRecords.styles'
import ConnectPatientCerner from '../MyProfile/ShowPopupModal'
import { LoginService } from 'services/login.service'
import { IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ImportEhrService } from 'services/importEhr.service'
import MrcTooltip from 'components/MrcTooltip'
import { useGlobalContext } from '../../../use/Context'
import { useHistory } from 'react-router-dom'
import { useDebounce } from '../../../hooks/useDebounce'
import CircularProgress from '@mui/material/CircularProgress'
import Popper from '@mui/material/Popper'
import { NewToolTipIcon } from '../../../components/NewToolTipIcon'
import { AssociatedProviderList } from './AssociatedProviderList'
import { getEhrRedirectData } from './ehrRedirectService'
const ImportRecords = () => {
    const history = useHistory()
    let isLoaded = false
    const [loading, setLoading] = useState(false)
    const [currentPageForOrganization, setCurrentPageForOrganization] = useState(1)
    // const [currentPageForDoctor, setCurrentPageForDoctor] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [state, setState] = useState({
        org: '',
        link: '',
        record: '',
        open: false,
        loading1: true,
        loading2: true,
        facilities: '',
        delFacility: '',
        success: '',
        errors: {},
        showPopup: false,
        profile: {},
        subjectFacility: '',
        veteranDataset: {},
    })
    const { ehrLoad, setEhrLoad, refreshEhrConnection } = useGlobalContext()
    const [orgSearch, setOrgSearch] = useState('')
    // const [doctorSearch, setDoctorSearch] = useState('')
    const { errors } = state

    const url = window.location.href
    const limit = 50
    const debouncedOrgInputValue = useDebounce(orgSearch, 500)
    // const debouncedDoctorInputValue = useDebounce(doctorSearch, 500)

    const getProfileData = async (loader = true) => {
        const email = localStorage.getItem('email')
        if (!email) return
        const { data: loginData } = await LoginService.getUser(email)
        if (loginData) {
            setState((prevState) => ({
                ...prevState,
                profile: loginData.profile,
            }))
        }
    }

    const setErrors = (errors) => {
        setState((prevState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                ...errors,
            },
        }))
    }

    // Returns list of EHRs the patient is connected to
    const getConnectedEHRRecords = async (afterImport) => {
        if (!afterImport) {
            setState((prevState) => ({
                ...prevState,
                loading1: true,
            }))
        }
        const data = await NewConnectedEHRrecordsAPI()

        if (data) {
            if (data.message !== 'No Data Found') {
                setState((prevState) => ({
                    ...prevState,
                    facilities: data,
                }))
            }
            if (afterImport) {
                setEhrLoad(false)
                popupNotification('Ehr Data loaded successfully!', true)
            }
        }
        setState((prevState) => ({
            ...prevState,
            loading1: false,
        }))
    }

    const handleScroll = (event, providerType) => {
        if (!hasMore) return
        const bottom = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) < 1
        if (bottom && !loading) {
            setLoading(true)
            if (providerType === 1) {
                getAllEndpoints(providerType, currentPageForOrganization + 1, debouncedOrgInputValue)
                setCurrentPageForOrganization(currentPageForOrganization + 1)
            }
            // if (providerType === 2) {
            //     getAllEndpoints(providerType, currentPageForDoctor + 1, debouncedDoctorInputValue)
            //     setCurrentPageForDoctor(currentPageForDoctor + 1)
            // }
        }
    }

    // Returns ehr endpoints and oauth request parameters for all connected hospitals
    const getAllEndpoints = async (providerType, page, search) => {
        if (providerType === 1) {
            setState((prevState) => ({
                ...prevState,
                ehrDataForOrganization: prevState.ehrDataForOrganization || [],
            }))
        }
        // if (providerType === 2) {
        //     setState((prevState) => ({
        //         ...prevState,
        //         ehrDataForDoctor: prevState.ehrDataForDoctor || [],
        //     }))
        // }
        const ehrList = await GetAllEndpointsAPI(providerType, page, search, limit)
        setLoading(false)
        if (ehrList?.length) {
            if (ehrList?.length < limit) setHasMore(false)
            const hospitalNameWithId = ehrList?.map((x) => {
                return {
                    ...x,
                    hospitalNameWithId: `${x.hospitalName} - (${x.ehrEndPointId})`,
                }
            })
            if (providerType === 1) {
                setState((prevState) => ({
                    ...prevState,
                    ehrDataForOrganization: [
                        ...new Set(
                            [...hospitalNameWithId, ...prevState.ehrDataForOrganization]?.map((s) => JSON.stringify(s))
                        ),
                    ]?.map((s) => JSON.parse(s)),
                }))
            }
            // if (providerType === 2) {
            //     setState((prevState) => ({
            //         ...prevState,
            //         ehrDataForDoctor: [
            //             ...new Set(
            //                 [...hospitalNameWithId, ...prevState.ehrDataForDoctor]?.map((s) => JSON.stringify(s))
            //             ),
            //         ]?.map((s) => JSON.parse(s)),
            //     }))
            // }
        } else {
            if (providerType === 1) {
                if (!debouncedOrgInputValue) setHasMore(false)
            }
            // if (providerType === 2) {
            //     if (!debouncedDoctorInputValue) setHasMore(false)
            // }
        }

        setState((prevState) => ({
            ...prevState,
            loading1: false,
        }))
    }

    const getVeteransEndpoints = async () => {
        const veteranList = await GetVeteransEndpointsAPI('Veteran Valor')
        if (veteranList?.length) {
            setState((prevState) => ({
                ...prevState,
                veteranDataset: veteranList[0],
            }))
        }
    }

    function getQueryParams(url) {
        const queryParams = {}
        const queryString = url.split('?')[1]
        if (queryString) {
            const pairs = queryString.split('&')
            pairs.forEach((pair) => {
                const [key, value] = pair.split('=')
                queryParams[key] = value
            })
        }
        return queryParams
    }

    const handleSearch = (providerType, input) => {
        if (providerType === 1) {
            setOrgSearch(input)
        }
        // if (providerType === 2) {
        //     setDoctorSearch(input)
        // }
        if (!input) getAllEndpoints(providerType, 1)
    }
    // Construct redirect url for patient to pull their records
    const facilityChange = (facility, reload) => {
        if (!facility) return

        const queryParams = getQueryParams(facility.authorizationPath)
        const authorizePath = facility.authorizationPath.split('?')?.length
            ? facility.authorizationPath.split('?')[0]
            : facility.authorizationPath
        const ehrCredentials = {
            client: {
                id: facility.clientId,
            },
            auth: {
                tokenHost: facility.tokenHost,
                tokenPath: facility.tokenPath,
                authorizePath: authorizePath,
                //     tokenHost: "https://fhirecho.fhirpoint.open.allscripts.com", //facility.tokenHost,
                //     tokenPath: "/connect/token", //facility.tokenPath, // '/connect/token',
                //     authorizePath: "/dbmotion/patient/connect/Authorize", //authorizePath, // '/connect/token', //authorizePath,
            },
            options: {
                authorizationMethod: facility.authorizationMethod,
            },
        }
        const oauthEhr = oauth2.create(ehrCredentials)
        const payload = {
            redirect_uri: facility.webRedirectUri,
            scope: facility.scopes,
            state: facility.ehrEndPointId,
            aud: facility.aud,
            ...queryParams,
        }
        if (facility?.ehrname?.toLowerCase() === 'altera') {
            delete payload.aud
            // payload.cust = 'HCAPROD_36'
        }
        // if (facility?.ehrname?.toLowerCase() === 'altera') {
        //     // const additionalParam = facility?.additionalParam.split(',')
        //     // const params = {}
        //     // additionalParam.forEach((param) => {
        //     //     const [key, value] = param.split('=')
        //     //     params[key] = value
        //     // })
        //     // payload = { ...payload, ...params }
        //     payload.cust = 'HCAPROD_36'
        // }

        let EhrAuthorizationUri = oauthEhr.authorizationCode.authorizeURL(payload)
        setState((prevState) => ({
            ...prevState,
            link: EhrAuthorizationUri,
            selectedEhrName: facility.ehrname,
        }))

        setErrors({ importRecords: null })
        // console.log({ EhrAuthorizationUri })
        localStorage.setItem('EHRconnected', facility.ehrname)
        localStorage.setItem('EHREndPointId', facility.ehrEndPointId)
        localStorage.setItem('EHRClientId', facility.clientId)
        localStorage.setItem('EHRGetTokenUrl', facility.getTokenPath)
        localStorage.setItem('EHRClientSecret', facility.clientSecret)
        if (reload) return EhrAuthorizationUri
    }

    // Redirects to selected hospital authorization portal for patient to pull their records into matchrite
    const importRecords = () => {
        if (isEmpty(state.link)) {
            return setErrors({ importRecords: 'Please choose and connect a facility before proceeding.' })
        }
        setState((prevState) => ({
            ...prevState,
            showPopup: true,
        }))
    }

    const organizationChange = (organization) => {
        setState((prevState) => ({
            ...prevState,
            org: organization.value,
        }))
        setErrors({ importClaims: null })
    }

    // Theoretically returns insurance claims but there is no discernable functionality
    const getClaims = () => {
        if (isEmpty(state.org)) {
            return setErrors({ importClaims: 'Please choose a payor before proceeding.' })
        }
        switch (state.org) {
            case 'Blue Button':
            case 'Medicare':
                window.location.replace(bluebuttonAuthorizationUri)
                break
            default:
        }
    }

    const onSendFacilityRequest = async () => {
        if (isEmpty(state.subjectFacility)) {
            return setErrors({ facilityRequest: 'Please type the facility name before proceeding.' })
        }
        try {
            const body = {
                ehrName: state.subjectFacility,
            }
            await ImportEhrService.sendEhrAddRequest(body)
            popupNotification(
                // 'Thank you.  Your request was sent to our MatchRite Administration. We will get it added as soon as possible.',
                'Thank you! Your message has been sent to MatchRite Care. We’ll add it as soon as possible.',
                true
            )
            setState((prevState) => ({
                ...prevState,
                // success:
                //     'Thank you.  Your request was sent to our MatchRite Administration. We will get it added as soon as possible.',
                subjectFacility: '',
            }))
        } catch (error) {
            popupNotification('Something went wrong.', false)
        }
    }

    // Redirects to VA authorization portal for veteran to return their service records, deployments, discharge status, and disability ratings
    const getVeteranVerification = () => {
        // const sandboxUrl=`${config.veteranCredentials.baseSandboxUrl}/oauth2/authorization?client_id=${config.veteranCredentials}&response_type=code&scope=openid profile email offline_access service_history.read disability_rating.read veteran_status.read&redirect_uri=${config.veteranCredentials}&state=${config.veteranCredentials}&code_challenge_method=${config.codeChallengeMethod}&code_challenge=${veteranCredentials.codeChallenge}`
        if (!state.veteranDataset.ehrname) return
        const sandboxUrl = `${config.veteranCredentials.baseSandboxUrl}oauth2/veteran-verification/v1/authorization?client_id=${config.veteranCredentials.clientId}&response_type=code&scope=openid profile email offline_access service_history.read disability_rating.read veteran_status.read&redirect_uri=${config.veteranCredentials.redirectUrl}&state=${config.veteranCredentials.stateCode}&code_challenge_method=${config.veteranCredentials.codeChallengeMethod}&code_challenge=${config.veteranCredentials.codeChallenge}`
        localStorage.setItem('EHRconnected', state.veteranDataset.ehrname)
        localStorage.setItem('EHREndPointId', state.veteranDataset.ehrEndPointId)
        window.location.replace(sandboxUrl)
    }

    const handleSubmitPatientId = (selectedProfileId) => {
        localStorage.setItem('selectedProfileId', selectedProfileId)
        setState((prevState) => ({
            ...prevState,
            showPopup: false,
        }))
        window.location.replace(state.link)
    }

    useEffect(() => {
        if (debouncedOrgInputValue) {
            getAllEndpoints(1, 1, debouncedOrgInputValue)
        }
    }, [debouncedOrgInputValue])

    // useEffect(() => {
    //     if (debouncedDoctorInputValue) {
    //         getAllEndpoints(2, 1, debouncedDoctorInputValue)
    //     }
    // }, [debouncedDoctorInputValue])

    const fetchData = async () => {
        await Promise.all([
            getConnectedEHRRecords(),
            getProfileData(),
            getAllEndpoints(1, 1),
            getVeteransEndpoints(),
            //  getAllEndpoints(2, 1)
        ])
    }
    useEffect(() => {
        if (!isLoaded) {
            isLoaded = true
            fetchData()
        }
    }, [])

    useEffect(() => {
        if (ehrLoad) {
            getConnectedEHRRecords(true)
        }
    }, [ehrLoad])

    useEffect(() => {
        if (url.includes('?')) {
            getEhrRedirectData(url, history, refreshEhrConnection)
        }
    }, [url])

    if (state.loading1) {
        return <Loader pageName="importrecords" />
    }

    return (
        <div className="container-right">
            <div className="white-container padding-28 importrecords">
                <div className="row">
                    <FieldsBox className="col-lg-6 mb-5">
                        <div className="import-sec">
                            <Autocomplete
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '56px',
                                    },
                                }}
                                style={{ flex: 1 }}
                                options={state.ehrDataForOrganization}
                                getOptionLabel={(option) => option.hospitalNameWithId}
                                getOptionSelected={(option) => option.ehrEndPointId}
                                onChange={(e, facility) => facilityChange(facility)}
                                ListboxProps={{
                                    onScroll: (e) => handleScroll(e, 1),
                                }}
                                PopperComponent={(props) => (
                                    <Popper {...props} style={{ width: props.style.width }}>
                                        {props.children}
                                        {loading && (
                                            <div style={{ textAlign: 'center', padding: '10px' }}>
                                                <CircularProgress size={24} />
                                            </div>
                                        )}
                                    </Popper>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Connect with Care Organization"
                                        variant="outlined"
                                        error={Boolean(errors.importRecords)}
                                        helperText={errors.importRecords}
                                        onChange={(e) => {
                                            handleSearch(1, e.target.value)
                                        }}
                                    />
                                )}
                            />
                            <ImportButton
                                disabled={state.showPopup}
                                value={state.value}
                                startIcon={<SaveAltOutlinedIcon />}
                                onClick={importRecords}
                            >
                                Import
                            </ImportButton>
                            {/* 'Find and Select a Provider Organization or facility that may retain an Electronic Health Record (EHR) associated with your personal medical history.', */}
                            {/* 'Use location info for improved search results.',
                                            <br />, 
                            'Authentication credentials for each respective provider portal is REQUIRED. MatchRite Care retains your collective EHR for All Providers listed to the right.',
                             */}
                            <MrcTooltip
                                text={
                                    <span>
                                        {[
                                            'Find and select a provider organization or facility that may hold an Electronic Health Record (EHR) related to your medical history.',
                                            <br />,
                                            <b>NOTE: </b>,
                                            'You`ll need login credentials for each provider`s portal',
                                            <br />,
                                        ]}
                                    </span>
                                }
                            >
                                {({ onOpen }) => (
                                    <IconButton color="primary" aria-label="Info" onClick={onOpen}>
                                        <NewToolTipIcon />
                                    </IconButton>
                                )}
                            </MrcTooltip>
                        </div>
                        {/* TODO: add this back in when we have the ability to import records from multiple providers */}
                        {/* <div className="import-sec bluebutton-sec">
                            <Autocomplete
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '56px',
                                    },
                                }}
                                style={{ flex: 1 }}
                                options={state.ehrDataForDoctor}
                                getOptionLabel={(option) => option.hospitalNameWithId}
                                getOptionSelected={(option) => option.ehrEndPointId}
                                onChange={(e, facility) => facilityChange(facility)}
                                ListboxProps={{
                                    onScroll: (e) => handleScroll(e, 2),
                                }}
                                PopperComponent={(props) => (
                                    <Popper {...props} style={{ width: props.style.width }}>
                                        {props.children}
                                        {loading && (
                                            <div style={{ textAlign: 'center', padding: '10px' }}>
                                                <CircularProgress size={24} />
                                            </div>
                                        )}
                                    </Popper>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Connect with Doctor"
                                        variant="outlined"
                                        error={Boolean(errors.importRecords)}
                                        helperText={errors.importRecords}
                                        onChange={(e) => {
                                            handleSearch(2, e.target.value)
                                        }}
                                    />
                                )}
                            />
                            <ImportButton
                                disabled={state.showPopup}
                                value={state.value}
                                startIcon={<SaveAltOutlinedIcon />}
                                onClick={() => importRecords(1)}
                            >
                                Import
                            </ImportButton>
                            <MrcTooltip
                                text={
                                    <span>
                                        {[
                                            'Find and Select your Doctor or practitioner that may retain an Electronic Health Record (EHR) associated with your personal medical history.',
                                            <br />,
                                            'Use location info for improved search results.',
                                            <br />,
                                            <b>NOTE: </b>,
                                            'Authentication credentials for each respective provider portal is REQUIRED. MatchRite Care retains your collective EHR for All Providers listed to the right.',
                                            <br />,
                                        ]}
                                    </span>
                                }
                            >
                                {({ onOpen }) => (
                                    <IconButton color="primary" aria-label="Info" onClick={onOpen}>
                                        <NewToolTipIcon />
                                    </IconButton>
                                )}
                            </MrcTooltip>
                        </div> */}

                        {/* TODO: Hiding claims for now */}
                        {/* <div className="import-sec bluebutton-sec">
                            <Autocomplete
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '56px',
                                    },
                                }}
                                style={{ flex: 1 }}
                                options={organizations}
                                getOptionLabel={(option) => option.value}
                                onChange={(e, organization) => organizationChange(organization)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Add payor"
                                        error={Boolean(errors.importClaims)}
                                        helperText={errors.importClaims}
                                    />
                                )}
                            />
                            <ImportButton startIcon={<SaveAltOutlinedIcon />} onClick={getClaims}>
                                Import
                            </ImportButton>
                            <MrcTooltip
                                text={
                                    <span>
                                        {[
                                            'This will re-direct you to the Payor',
                                            <br />,
                                            <b>NOTE: </b>,
                                            'Authentication credentials are REQUIRED.',
                                        ]}
                                    </span>
                                }
                            >
                                {({ onOpen }) => (
                                    <IconButton color="primary" aria-label="Info" onClick={onOpen}>
                                        <NewToolTipIcon />
                                    </IconButton>
                                )}
                            </MrcTooltip>
                        </div> */}

                        {/* todo city state address */}
                        {/* <RequestedProviderData errors={errors} /> */}

                        <VeteransBox className="import-sec veteran-sec">
                            <div className="va-label">
                                <h6>For Veterans - Import disability rating</h6>
                            </div>
                            <ImportButton onClick={getVeteranVerification} startIcon={<SaveAltOutlinedIcon />}>
                                Import
                            </ImportButton>
                            {/* 'This will re-direct you to the VA.gov access portal.', */}
                            {/* 'Authentication credentials are REQUIRED. MatchRite Care retains your Disability Rating for association with your medical record.', */}
                            <MrcTooltip
                                text={
                                    <span>
                                        {[
                                            'This will take you to the VA.gov access portal.',
                                            <br />,
                                            <b>NOTE: </b>,
                                            'You’ll need login credentials for each provider’s portal.',
                                        ]}
                                    </span>
                                }
                            >
                                {({ onOpen }) => (
                                    <IconButton color="primary" aria-label="Info" onClick={onOpen}>
                                        <NewToolTipIcon />
                                    </IconButton>
                                )}
                            </MrcTooltip>
                        </VeteransBox>
                        <Box sx={{ mt: 10 }}>
                            {/* If you don't see your provider group listed, let us know. We will get it added as soon
                                as possible. */}
                            <FacilityLabel>
                                If your provider group isn’t listed, please send us a message in the box below with the
                                facility name, city, and state. We’ll add it as soon as possible.
                            </FacilityLabel>
                            <MissingFaciltyBox>
                                <TextField
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '60px',
                                        },
                                    }}
                                    // multiline
                                    // rows={2}
                                    // maxRows={4}
                                    label="Enter text here"
                                    // label="Enter name, city and state for requested Provider."
                                    variant="outlined"
                                    value={state.subjectFacility}
                                    onChange={(e) =>
                                        setState((prevState) => ({
                                            ...prevState,
                                            subjectFacility: e.target.value,
                                        }))
                                    }
                                    error={Boolean(errors?.facilityRequest)}
                                    helperText={errors?.facilityRequest}
                                />
                                <ImportButton
                                    sx={{ height: '60px' }}
                                    onClick={onSendFacilityRequest}
                                    startIcon={<OutboxOutlinedIcon />}
                                >
                                    Send
                                </ImportButton>
                            </MissingFaciltyBox>
                            {state.success && (
                                <Typography style={{ color: 'green' }}>
                                    {state.success}
                                    <IconButton
                                        onClick={() =>
                                            setState((prevState) => ({
                                                ...prevState,
                                                success: '',
                                            }))
                                        }
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Typography>
                            )}
                        </Box>
                    </FieldsBox>
                    <div className="col-lg-1 d-none d-lg-block">
                        <ColumnsHr />
                    </div>
                    {/* Data Grid with list of provider facilities the patient is connected to */}
                    <AssociatedProviderList
                        state={state}
                        setState={setState}
                        facilityChange={facilityChange}
                        getConnectedEHRRecords={getConnectedEHRRecords}
                    />
                    {/* <div className="col-lg-12 col-md-12">
                 <Dependents />
             </div> */}
                </div>
                {state.showPopup && (
                    <ConnectPatientCerner
                        title="Connect Patient Import Record"
                        isOpen={state.showPopup}
                        onClose={() =>
                            setState((prevState) => ({
                                ...prevState,
                                showPopup: false,
                            }))
                        }
                        onAdd={(id) => handleSubmitPatientId(id)}
                        user={{
                            id: state.profile.id,
                            firstName: state.profile.firstName,
                            lastName: state.profile.lastName,
                        }}
                        ehrName={state.selectedEhrName}
                    />
                )}
            </div>
        </div>
    )
}

export default ImportRecords
