// import { vaPatientToken } from 'services/vaServices'
import { addPatientToEpic } from 'services/epicServices'
// import { addPatientToNextgen } from 'services/nextgenServices'
// import { addPatientToCerner } from 'services/cernerServices'
// import { addPatientToAthena } from 'services/athenaServices'
import { addPatientToMeditech } from 'services/meditechServices'
import { addPatientToEClinical } from 'services/eclinicalServices'
import { bluebuttonPatientToken } from 'services/bluebuttonServices'
// import { addPatientToAllScripts } from 'services/allscriptsServices'
import { processVeteranValor } from 'services/veteran.service'
// import { processVeteranValor, veteranAuthenticationToken } from 'services/veteran.service'

export const getEhrRedirectData = (url, history, refreshEhrConnection) => {
    const EHRConnectedTo = localStorage.getItem('EHRconnected')
    // const EHRClientId = localStorage.getItem('EHRClientId')
    // const EHRGetTokenUrl = localStorage.getItem('EHRGetTokenUrl')
    // const EHRClientSecret = localStorage.getItem('EHRClientSecret')
    const params = new URLSearchParams(url.split('?')[1])
    const code = params.get('code')
    const state = params.get('state')
    const queryParams = ''
    history.replace({
        search: queryParams,
    })
    if (!code || !state) return
    refreshEhrConnection()
    switch (EHRConnectedTo?.toLocaleLowerCase()) {
        // case `Cerner`.toLowerCase():
        //     addPatientToCerner(code, state)
        //     break
        case `Cerner`.toLowerCase():
        case `Epic`.toLowerCase():
        case `Nextgen`.toLowerCase():
        case `Athena`.toLowerCase():
        case `Veradigm`.toLowerCase():
        case `AllScripts`.toLowerCase():
        case `Altera`.toLowerCase():
        case 'Veterans Affairs'.toLowerCase():
            addPatientToEpic(code, state)
            break
        // case `Nextgen`.toLowerCase():
        //     addPatientToNextgen(code, state)
        //     break
        // case `Veradigm`.toLowerCase():
        // case `AllScripts`.toLowerCase():
        // case `Altera`.toLowerCase():
        //     addPatientToAllScripts(code, state)
        //     break
        case `Meditech`.toLowerCase():
            addPatientToMeditech(code, state)
            break
        // case `Athena`.toLowerCase():
        //     // athenaPatientToken(EHRClientId, EHRGetTokenUrl)
        //     addPatientToAthena(code, state)
        //     break
        // case 'Veterans Affairs'.toLowerCase():
        //     vaPatientToken(code, state)
        //     break
        case 'Eclinical Works'.toLowerCase():
            addPatientToEClinical(code, state)
            // eclinicalPatientToken(EHRClientId, EHRGetTokenUrl)
            break
        case 'Veteran Valor'.toLowerCase():
            processVeteranValor(code)
            // veteranAuthenticationToken(code, state)
            break

        default:
            bluebuttonPatientToken()
            break
    }
}
